/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */
/* *{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
body{
	font-family: 'Poppins', sans-serif;
} */

.contentImage {
	width: 100%;
}

.typeoneglassmorphism {
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 8px 32px 0 rgba(38, 100, 80, 0.37);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	
}

.typetwoglassmorphism {
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0 8px 32px 0 rgba(38, 100, 80, 0.37);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 10px 0 0 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);

}

.timeline-section {
	background-color: #24292d;
	min-height: 100vh;
	padding: 100px 15px;
}

.timeline-items {
	max-width: 1000px;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	position: relative;
}

.timeline-items::before {
	content: '';
	position: absolute;
	width: 2px;
	height: 100%;
	background-color: #60FFCC;
	left: calc(50% - 1px);
}

.timeline-item {
	margin-bottom: 40px;
	width: 100%;
	position: relative;
}

.timeline-item:last-child {
	margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
	padding-right: calc(50% + 30px);
	text-align: right;
}

.timeline-item:nth-child(even) {
	padding-left: calc(50% + 30px);
}

.timeline-dot {
	height: 16px;
	width: 16px;
	background-color: #60FFCC;
	position: absolute;
	left: calc(50% - 8px);
	border-radius: 50%;
	top: 10px;
}

.timeline-date {
	font-size: 18px;
	color: #60FFCC;
	margin: 6px 0 15px;
}

.timeline-content {
	/* background-color: #2f363e; */
	/* padding: 30px; */
	/* border-radius: 5px; */
}

.timeline-content h3 {
	font-size: 20px;
	color: #ffffff;
	margin: 0 0 10px;
	text-transform: capitalize;
	font-weight: 500;
}

.timeline-content p {
	color: #c8c8c8;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
}

/* responsive */
@media(max-width: 767px) {
	.timeline-items::before {
		left: 12px;
	}

	.timeline-item:nth-child(odd),.timeline-item:nth-child(even) {
		padding-right: 20px;
		text-align: left;
	}

	.timeline-item:nth-child(odd),
	.timeline-item:nth-child(even) {
		padding-left: 25px;
	}

	.timeline-dot {
		left: 5px;
	}
	.timeline-item {
		/* margin-bottom: 40px; */
		/* width: 100%; */
		position: relative;
		height: 100px;
	}
	

	
}