body {
  /* font-family: 'Poppins', sans-serif; */
  overflow-x: hidden;
  /* background: linear-gradient(150deg, #156dd2 0%, #000851 100%); */
  background-image: url('../src//Components/Assets/Images/allone2_1_29.png');
    background-size:cover;
}

@font-face {
  font-family: actayR;
  src: url('./Components/Fonts/Actay-Regular.otf');
}

@font-face {
  font-family: actaywB;
  src: url('./Components/Fonts/ActayWide-Bold.otf');
}

@font-face {
  font-family: reservationwideBold;
  src: url('./Components/Fonts/Reservation\ Wide\ Bold.otf');
}

@font-face {
  font-family: actayCT;
  src: url('./Components/Fonts/ActayCondensed-Thin.otf');
}

.fontactayR{
  font-family: actayR;
}

.fontactayWB{
  font-family: actaywB;
}


.fontReservationWB{
  font-family: reservationwideBold;
}

.fontactayCT{
  font-family: actayCT;
}




* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;

}

::-webkit-scrollbar {
  width: 0px;
}


a {
  text-decoration: none;
  color: black;
}

.contentLogo{

  /* border: 2px solid red; */
  margin-top: 80px;
}


.introContainer{
  background: url('./Components/Assets/Images/Home_50_24.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* border: 2px solid green; */
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.introSpan{
  color: #E99D23;
  font-weight: 600;
}

.textIntro{
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 400;
  

}


.introWrapper{
  /* border: 2px solid blueviolet; */
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* position: relative; */
  
  
  margin: 80px auto 0 auto;
}

@media screen and (max-width:290px) and (min-width:190px)  {
  
  .heroImage{
    width: 100%;
    /* margin-top: 100px; */
    /* border: 2px solid red; */
  }
  .heroMainMsg{
    font-size: 30px;
  }
  .herohackathonmsg{
    font-size: 25px;
  }
  .tickerCellValue {
    font-size: 1.5rem;
}
  .introWrapper{
    width: 79%;
    font-size: 1.5rem;
  }
  .contentLogo{
    width:250px;

  }
  .mottoImage{
    width: 400px;
    position: relative;
    left: 28px;
    
  }
  .socialMedias{
    display: none;
  }

  .pageContainer{
    position: relative;
    left: -16px;
  }
  .tickerCellValue {
    font-size: .8rem;
}
.separator {
  font-size: 0.8rem;
}
header{
  padding: 0 2rem;
}
  
  
  
  



  
  
}




@media screen and (max-width:559px) and (min-width:290px)  {
  
  .heroImage{
    width: 100%;
    /* margin-top: 100px; */
    /* border: 2px solid red; */
  }
  .heroMainMsg{
    font-size: 40px;
  }
  .herohackathonmsg{
    font-size: 25px;
  }
  .tickerCellValue {
    font-size: 2.5rem;
}
  .introWrapper{
    width: 79%;
    font-size: 1.5rem;
  }
  .contentLogo{
    width:250px;

  }
  .mottoImage{
    width: 400px;
    position: relative;
    left: 28px;
    
  }
  .socialMedias{
    display: none;
  }

  .pageContainer{
    position: relative;
    left: -16px;
  }
  .tickerCellValue {
    font-size: 1.5rem;
}
  .separator {
    font-size: 1.5rem;
  }
  header{
    padding: 0 2rem;
  }
  
  
  



  
  
}
@media screen and (max-width:759px) and (min-width:600px)  {
  
  .heroMainMsg{
    font-size: 42px;
  }
  .herohackathonmsg{
    font-size: 25px;
  }
  .introWrapper{
    width: 78%;
    font-size: 3rem;
  }
  .contentLogo{
    width:450px;

  }
  .mottoImage{
    width: 500px;
    position: relative;
    left: 28px;
  }
  .socialMedias{
    display: none;
  }
  .pageContainer{
    position: relative;
    left: -18px;
  }
  .tickerCellValue {
    font-size: 2.5rem;
}
.separator {
  font-size: 2.0rem;
}
header{
  padding: 0 2rem;
}
  
  
}
  
  @media screen and (max-width:991px) and (min-width:760px) {
    

    .heroMainMsg{
      font-size: 70px;
      text-align: center;
    }
    .herohackathonmsg{
      font-size: 40px;
      text-align: center;
      padding-right: 50px;
    }
    .introWrapper{
      width: 78%;
      font-size: 3rem;
    }
    .contentLogo{
      width:450px;
  
    }
    .mottoImage{
      width: 800px;
      position: relative;
      left: 35px;
    }
    .socialMedias{
      display: none;
    }
    .pageContainer{
      /* position: relative; */
      /* left: -18px; */
      align-items: center;
    }
    .tickerCellValue {
      font-size: 2.5rem;
  }
  .separator {
    font-size: 2.0rem;
  }
  header{
    padding: 0 2rem;
  }
  
  }

  @media screen and (max-width:1200px) and (min-width:992px) {

    .heroMainMsg{
      font-size: 70px;
    }
    .herohackathonmsg{
      font-size: 30px;
    }
    .introWrapper{
      width: 78%;
      font-size: 4rem;
    }
    .contentLogo{
      width:450px;
  
    }
    .mottoImage{
      width: 800px;
      position: relative;
    left: 40px;
    }
    .pageContainer{
      position: relative;
      left: -18px;
    }
    .tickerCellValue {
      font-size: 2.5rem;
  }
  .separator {
    font-size: 2.0rem;
  }
  header{
    padding: 0 0 0 2rem;
  }
    
  }
  
@media screen and (min-width:1200px) {
  .heroMainMsg{
    font-size: 70px;
  }
  .herohackathonmsg{
    font-size: 30px;
  }
  .introWrapper{
    width: 78%;
    font-size: 80px;
  }
  .contentLogo{
    width:450px;

  }
  .mottoImage{
    width:1000px;
    position: relative;
    left: 40px;
  }
  .pageContainer{
    position: relative;
    left:-18px;
  }
  .tickerCellValue {
    font-size: 2.5rem;
}
.separator {
  font-size: 2.0rem;
}
header{
  padding: 0 0 0 2rem;
}
}

