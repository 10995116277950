.mascotwithimage{
    width: 100%;
    /* border:"2px solid yellow"; */
    animation: anim 3s infinite;
}
.maincontainer{
    border-radius: 25px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 8px 32px 0 rgba(38, 100, 80, 0.37);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.detailbtn{
    /* width:"50px"; */
    height: 7vh;
    border-radius: 25px;
    background-color: #60FFCC;
    border: none;
    font-weight: 800;
}

.detailbtn:hover{
    background-color: #1e7358;
    color: white;
}


@keyframes anim {
    0% {
      transform: translateY(-10px);
    }
  
    50% {
      transform: translateY(-30px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }