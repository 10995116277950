.aboutmain{
    /* background: url('../Assets/Images/about11f_50.png'); */
    background-size: cover;
    border: none;
}


.whyHackx{
    color: white;
    font-weight: 600;
}


.sliderContainer{
    /* border: 2px solid yellow; */
    /* width: 30%; */
    /* background-size: cover; */
    background-repeat: no-repeat;
    text-align: center;
    /* overflow: hidden; */
    /* box-sizing: border-box; */
    /* position: relative; */
    /* height: 50vh; */

}

.mottoImage{
    
    
    animation: anim 3s infinite;
}



@keyframes anim {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-20px);
    }
  
    100% {
      transform: translateY(0);
    }
  }