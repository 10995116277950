.tickerCell {
     /* flex: 1; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border: 2px solid purple; */
    width: 100px;

     
    
     
}

.tickerCellLabel {
    /* font-style: italic; */
    font-size: 12px;
}

