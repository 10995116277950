@import '../colours';

.tickerShell {
    // align-self: stretch;
    display: flex;
    // border: 1px solid blue;
    // padding: 60px;

    .timeIsUp {
        flex: 1;
        text-align: center;
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 600;
    }
}