.footer {

    background-color: rgba(0, 0, 0);
    /* border-radius: 36px 36px 0 0; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    /* backdrop-filter: blur(4.9px); */
    /* -webkit-backdrop-filter: blur(4.9px); */
    /* border: 1px solid rgba(255, 255, 255, 0.71); */
    color: white;
    /* font-family: 'Poppins', sans-serif; */
  }

  .footerUniLogo{
    width: 50px;
    
  }

  .clickablebtn:hover{
    border: 2px solid white;
    padding: 5px;
    color: black;
    border-radius: 10px;
  }

  .clickablebtn:active{
    background-color: white;
    color: black;
  }