.home{
    /* background-image: url('../Assets/Images/Home_50.png'); */
    background-size:cover;
}

.herohackathonmsg{
    color: white;
    margin-bottom: 0;
}


.heroMascot{
    animation: anim 3s infinite;
    /* border: 3px solid red; */
}

.heroMainMsg{
  font-weight: 900;
}


@keyframes anim {
    0% {
      transform: translateY(-10px);
    }
  
    50% {
      transform: translateY(-30px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }