@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	/* font-family: "poppins", sans-serif; */
	overflow-x: hidden;
} 

:root {
	--mainColor: #000000;
	--mainColorLight: #5767aa;
	--secondaryColor: #60FFCC;
	--textColor: #eee;
} 


.jrlogo{
	width: 100px;
  }

header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	/* padding: 0 0 0 2rem; */
	/* padding: 0 2rem; */
	background-color: var(--mainColor);
	color: var(--textColor);
	font-weight: 600;
	position: fixed;
	/* border: 2px solid purple; */
	
	/* position: sticky; */
	top: 0;
	left: 0;
	
	
	z-index: 12;
}
nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
	font-size: large;
}

nav a:hover {
	color: var(--secondaryColor);
}
header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
	
}
nav{
	/* border: 2px solid green; */
	/* position: relative; */
	/* left: 200px; */
}

header div,
nav {
	display: flex;
	align-items: center;
	
}
@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display:flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
		z-index: 10;
		
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
	
}