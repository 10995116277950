.pageContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    
    /* border: 2px solid red; */
    width: 100%;
    max-width: max-content;
    color: white;
  
    
    
    
  
  }


  h1 {
    font-size: 5.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2.3rem;
  }


  p {
    margin-bottom: 1.8rem;
  }